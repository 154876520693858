














import { Component } from 'vue-property-decorator'
import AbstractHeader from './AbstractHeader'
import can from '@/shared/helpers/can'
import Model from '@/shared/interfaces/model'
import DataTableAction from '@/shared/classes/data-table/data-table-action'

@Component
export default class ActionHeader extends AbstractHeader {
  isPhysicalIcon(icon: string) {
    return icon.includes('.')
  }

  hasAtLeastOneAction(): boolean {
    return this.table.actions.some((action: DataTableAction) => this.hasPermission(action.permissions, this.item))
  }

  hasPermission(permissions: string[], item: Model): boolean {
    if (! permissions) return true

    return can(permissions, false, item)
  }
}
